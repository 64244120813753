import axios from '@interceptor'
import { defineStore } from 'pinia'
import { inject } from 'vue'
import router from '../router'
import { useAuthStore } from './auth'
import { useNavbarStore } from './navbar'
import { useAppStore } from './modules/app'

export const useHttpStore = defineStore('http', {
    state: () => {
        const env = inject('env')
        const authStore = useAuthStore()

        return {
            baseURL: env.api.appbox.baseURL,
            api: axios.create({
                baseURL: env.api.appbox.baseURL,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }),
            apiOAuth: axios.create({
                baseURL: env.api.appbox.baseURL,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authStore.token}`
                }
            }),
            apiOAuthMultipart: axios.create({
                baseURL: env.api.appbox.baseURL,
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
        }
    },
    actions: {
        async sendAboutApp(data) {
            const mountedData = {
                update_section_id: 1,
                name: data.name,
                app_description: data.app_description,
                support_email: data.support_email,
                billing_model: data.billing_model,
                is_private: data.is_private,
                app_billing_rate:
                    data.billing_model === '1' ? data.app_billing_rate : ''
            }

            return await axios
                .post(`${this.baseURL}/app/`, mountedData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async sendImagesApp(appID, data) {
            const mountedData = {
                update_section_id: 3,
                app_id: appID,
                logo: data.logo
            }

            return await axios
                .post(`${this.baseURL}/app/`, mountedData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async sendConfigToApp(appID, data) {
            const mountedData = {
                update_section_id: 2,
                app_id: appID,
                permissions: data.permissions
            }

            return await axios
                .post(`${this.baseURL}/app/`, mountedData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async sendDeveloperInfosToApp(appID, data) {
            const mountedData = {
                id: appID,
                app_external_host: data.app_external_host,
                url_repository: data.url_repository,
                app_external_validation: data.app_external_validation
            }

            return await axios
                .post(`${this.baseURL}/app/developer`, mountedData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async editAboutApp(appID, data) {
            const mountedData = {
                update_section_id: 1,
                id: appID,
                ...data
            }

            return await axios
                .post(`${this.baseURL}/app/update-app`, mountedData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async editImagesApp(appID, data) {
            const appStore = useAppStore()

            const mountedData = {
                update_section_id: 3,
                id: appID,
                logo: appStore.app.logo ?? data.logo
            }

            return await axios
                .post(`${this.baseURL}/app/update-app`, mountedData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async editConfigApp(appID, data = {}) {
            const appStore = useAppStore()

            const mountedData = {
                update_section_id: 2,
                id: appID,
                permissions: data.permissions ?? appStore.app.permissions,
                url_repository: data.systemURL ?? appStore.app.systemURL,
                app_external_host: data.host ?? appStore.app.host,
                app_external_validation: data.validationURL ?? appStore.app.validationURL
            }

            return await axios
                .post(`${this.baseURL}/app/update-app`, mountedData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async getPermissions() {
            return await axios
                .get(`${this.baseURL}/apps/permissions`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async getApps() {

            return await axios
                .get(`${this.baseURL}/apps`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        router.push({ name: 'login' })
                    }

                    throw new Error(error.response)
                })
        },
        async requestForgotPassword(email) {
            return await axios
                .post(`${this.baseURL}/auth/user/request-reset-password`, {
                    email
                })
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async resetPassword(data) {
            return await axios
                .post(`${this.baseURL}/auth/user/reset-password`, data)
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
        async validateToken(reset = false) {
            const authStore = useAuthStore()
            const navbarStore = useNavbarStore()

            return authStore.getAuthenticatedStatus
                ? await axios
                      .get(`${this.baseURL}/validate-token`)
                      .then(response => {
                          return response
                      })
                      .catch((error) => {
                          if (reset && authStore.getAuthenticatedStatus) {
                              localStorage.removeItem('user-token')
                              localStorage.removeItem('auth')
                              authStore.token = null
                              authStore.setStatusAuthentication(false)
                              navbarStore.setPublicMode()
                          }

                          throw new Error(error.response)
                      })
                : this
        },
        async getDraftApp() {
            const authStore = useAuthStore()

            return await axios
                .get(`${this.baseURL}/apps/has-draft-app`)
                .then(response => {
                    if (response.data.data === false) {
                        return false
                    }

                    return response
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        authStore.setStatusAuthentication(false)
                        router.push({ name: 'login' })
                    }

                    throw new Error(error.response)
                })
        },
        async getAppById(id) {
            return await axios
                .get(`${this.baseURL}/app/${id}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        router.push({ name: 'login' })
                    }

                    throw new Error(error.response)
                })
        },
        async updateAppStatus(appID, status) {
            const mountedData = {
                id: appID,
                status_id: status
            }

            return await axios
                .post(`${this.baseURL}/app/update-app-status`, mountedData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    throw new Error(error.response)
                })
        },
    }
})
