import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useHttpStore } from '@/stores/http'
import _ from 'lodash'

export const ScreenMode = {
    CREATION: 1,
    EDITING: 2,
    DRAFT: 3
}

export const useAppStore = defineStore('app', () => {
    const validateEditForm = ref(false)
    const isSelectedApp = ref(false)
    const app = ref({
        id: undefined,
        nameApp: undefined,
        supportEmail: undefined,
        applicationDescription: undefined,
        paymentModel: undefined,
        billingModel: undefined,
        billing: undefined,
        permissions: undefined,
        logo: undefined,
        host: undefined,
        systemURL: undefined,
        validationURL: undefined
    })
    const modificatedSteps = ref([])
    const screenMode = ref(ScreenMode.CREATION)

    async function verifyScreenMode() {
        const route = useRoute()

        if (route.params.id) {
            screenMode.value = ScreenMode.EDITING
            return
        }

        if (screenMode.value === ScreenMode.DRAFT && app.value.id) {
            isSelectedApp.value = true
            await getAppById(app.value.id)
            return
        }

        await hasDraft()

        if (screenMode.value !== ScreenMode.DRAFT && !route.params.id) {
            screenMode.value = ScreenMode.CREATION
        }

        return screenMode
    }

    async function hasDraft() {
        const httpStore = useHttpStore()

        await httpStore.getDraftApp().then(async (response) => {
            if (response.data.data) {
                screenMode.value = ScreenMode.DRAFT
                app.value = await mapApp(response.data.data)
            }
        })
    }

    async function getAppById(id) {
        const httpStore = useHttpStore()

        await httpStore.getAppById(id).then(async (response) => {
            const permissions = response.data.data.app_permissions ?? []
            app.value = await mapApp(response.data.data.app, permissions)
        })

        return app.value
    }

    async function mapApp(payload, permissions = []) {
        const mapedApp = {
            id: payload.id,
            nameApp: payload.name,
            supportEmail: payload.support_email,
            applicationDescription: payload.app_description,
            logo: payload.url_logo,
            host: payload.app_external_host,
            statusID: payload.status_id,
            systemURL: payload.external_site,
            validationURL: payload.app_external_validation,
            billingModel: payload.billing_model,
            billing: _.toString(payload.app_billing_rate),
            isPrivate: payload.is_private,
            ...(permissions.length > 0 && { permissions })
        }

        return mapedApp
    }

    async function mapAppPayload(payload) {
        const mapedPayload = {
            name: payload.nameApp,
            support_email: payload.supportEmail,
            app_description: payload.applicationDescription,
            url_logo: payload.logo,
            billing_model: payload.billingModel,
            app_billing_rate: +payload.billing,
            is_private: payload.isPrivate,
        }

        return mapedPayload
    }

    async function sendEditedApp() {
        const httpStore = useHttpStore();
    
        const taskMap = {
            'aboutApp': async () => httpStore.editAboutApp(app.value.id, await mapAppPayload(app.value)),
            'imagesApp': () => httpStore.editImagesApp(app.value.id, { logo: app.value.logo }),
            'configApp': () => httpStore.editConfigApp(app.value.id)
        };
    
        const tasks = modificatedSteps.value
            .filter(step => taskMap[step])
            .map(step => taskMap[step]());
    
        try {
            await Promise.all(tasks);
        } catch (error) {
            console.error('Failed to send edited app:', error);
        }
    
        return app.value;
    }
    

    async function resetForNewApp() {
       try {
        app.value = {
            id: undefined,
            nameApp: undefined,
            supportEmail: undefined,
            applicationDescription: undefined,
            paymentModel: undefined,
            billingModel: undefined,
            billing: undefined,
            isPrivate: undefined,
            permissions: undefined,
            logo: undefined,
            host: undefined,
            systemURL: undefined
        }

        isSelectedApp.value = false
        screenMode.value = ScreenMode.CREATION

        return true
       } catch (error) {
           return false
       }
    }

    function addStepModificated(step) {
        if (!modificatedSteps.value.includes(step)) {
            modificatedSteps.value.push(step)
        }
    }

    return {
        validateEditForm,
        app,
        screenMode,
        verifyScreenMode,
        getAppById,
        sendEditedApp,
        resetForNewApp,
        addStepModificated
    }
})
